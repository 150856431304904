<template>
  <v-text-field
    v-if="inputType == 'string'"
    v-bind="$attrs"
    color="indigo darken-4"
    persistent-placeholder
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.textRules"
    :disabled="isFetching || disabled"
    :readonly="readonly"
    :dense="dense"
    :prefix="prefix"
    @keypress="formatInput($event)"
  />

  <v-text-field
    v-else-if="inputType == 'number'"
    v-bind="$attrs"
    hide-spin-buttons
    color="indigo darken-4"
    persistent-placeholder
    :hint="hint"
    :persistent-hint="$validate.DataValid(hint)"
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.numberRules"
    :disabled="isFetching || disabled"
    type="number"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  />

  <v-text-field
    v-else-if="inputType == 'phone'"
    v-bind="$attrs"
    hide-spin-buttons
    color="indigo darken-4"
    persistent-placeholder
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.phoneRules"
    :disabled="isFetching || disabled"
    type="number"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  />

  <v-text-field
    v-else-if="inputType == 'email'"
    v-bind="$attrs"
    color="indigo darken-4"
    persistent-placeholder
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.emailRules"
    :disabled="isFetching || disabled"
    type="email"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  />

  <v-textarea
    v-else-if="inputType == 'textarea'"
    v-bind="$attrs"
    color="indigo darken-4"
    persistent-placeholder
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :placeholder="placeHolder"
    :rows="rows ? rows : 3"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.textRules"
    :disabled="isFetching || disabled"
    :readonly="readonly"
  />

  <div v-else-if="inputType == 'checkbox' && options.length > 0">
    <v-checkbox
      v-for="(item, index) in options"
      :key="`checkbox-${index}`"
      v-bind="$attrs"
      class="my-0 py-0"
      color="indigo darken-4"
      :value="item.value"
      @change="updateCheckbox($event)"
      :label="item.text"
      :rules="rules.checkboxRules"
      :disabled="isFetching || disabled"
      v-model="checkboxInput"
    />
  </div>
  <div v-else-if="inputType == 'checkbox' && options.length <= 0">
    <v-checkbox
      v-bind="$attrs"
      class="my-0 py-0"
      color="indigo darken-4"
      @change="$emit('input', typeof $event === 'boolean' ? $event : checkboxVal)"
      :label="label"
      :rules="rules.textRules"
      :disabled="isFetching || disabled"
      v-model="checkboxVal"
    />
  </div>

  <v-select
    v-else-if="inputType == 'select'"
    v-bind="$attrs"
    color="indigo darken-4"
    persistent-placeholder
    :items="options"
    :value="value"
    @input="$emit('input', $event)"
    item-text="text"
    item-value="value"
    :label="label"
    outlined
    :placeholder="placeHolder"
    hide-details="auto"
    :disabled="isFetching || disabled"
    no-data-text="沒有資料"
    :readonly="readonly"
    :required="required"
    :rules="rules.textRules"
    :dense="dense"
    :multiple="multiple"
    :menu-props="{ bottom: true, offsetY: true }"
    @change="$emit('selectChange', $event)"
  />

  <v-dialog v-else-if="inputType == 'dateRangePicker'" ref="dateRangeModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="isFetching || disabled"
        :value="displayCustomDateRange(selectDateRange)"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRangeRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectDateRange"
      color="primary accent-4"
      @input="$emit('pickerInput', $func.orderDateArrInput($event))"
      range
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="isFetching || disabled"
      :min="minDay"
      :max="maxDay"
    >
      <v-btn
        text
        small
        color="error"
        @click="
          $refs.dateRangeModal.save([])
          $emit('input', $func.orderDateArrInput([]))
          $emit('dateRangeDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectDateRange = saveSelectDateRange
          $emit('dateRangeDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.dateRangeModal.save(selectDateRange)
          $emit('input', $func.orderDateArrInput(selectDateRange))
          $emit('dateRangeDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>

  <v-dialog v-else-if="inputType == 'datePicker'" ref="dateModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="isFetching || disabled"
        :value="selectDate"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectDate"
      color="primary accent-4"
      @input="$emit('pickerInput', $event)"
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="disabled"
      :min="minDay"
      :max="maxDay"
      :allowed-dates="allowedDates"
    >
      <v-btn
        text
        small
        color="error"
        v-if="!allowClearDate"
        @click="
          $refs.dateModal.save('')
          $emit('input', '')
          $emit('dateDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectDate = saveSelectDate
          $emit('dateDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.dateModal.save(selectDate)
          $emit('input', selectDate)
          $emit('dateDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>

  <v-menu
    v-else-if="inputType == 'timePicker'"
    ref="timePickerMenu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :label="label"
        :append-icon="icons.mdiClockTimeFourOutline"
        readonly
        hide-details="auto"
        v-bind="attrs"
        v-on="on"
        persistent-placeholder
        :placeholder="placeHolder"
        :rules="rules.timeRules"
        :disabled="isFetching || disabled"
        outlined
      />
    </template>
    <v-time-picker
      v-if="menu"
      :value="value"
      full-width
      @input="$emit('input', $event)"
      no-title
      :disabled="isFetching || disabled"
    />
  </v-menu>

  <v-file-input
    v-else-if="inputType == 'upload'"
    ref="image"
    :label="label"
    hide-details="auto"
    persistent-placeholder
    :placeholder="placeHolder"
    :rules="rules.uploadRules"
    outlined
    prepend-icon=""
    :append-icon="icons.mdiPaperclip"
    @change="onFilePicked"
    accept="image/png, image/jpeg, image/bmp, application/pdf"
    :disabled="isFetching || disabled"
  />

  <CustomDigitalTimepicker
    v-else-if="inputType == 'digitalTimePicker'"
    :label="label"
    :time.sync="inputTime"
    :required="required"
    :disabled="isFetching || disabled"
  ></CustomDigitalTimepicker>
</template>

<script>
import { mapState } from 'vuex'
import { mdiCalendar, mdiClockTimeFourOutline, mdiPaperclip } from '@mdi/js'
import moment from 'moment'
import CustomDigitalTimepicker from '../CustomDigitalTimepicker.vue'

export default {
  name: 'FormControl',
  components: { CustomDigitalTimepicker },
  props: {
    placeHolder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputNumberOnly: {
      type: Boolean,
      default: false,
    },
    inputFloatingOnly: {
      type: Boolean,
      default: false,
    },
    inputAlphaNumericOnly: {
      type: Boolean,
      default: false,
    },
    validateFloating: {
      type: Boolean,
      default: false,
    },
    validateNumber: {
      type: Boolean,
      default: false,
    },
    validatePhone: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    customChecking: {
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    minDay: {
      type: String,
      default: '',
    },
    maxDay: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    hint: {
      type: String,
      default: '',
    },
    allowClearDate: {
      type: Boolean,
      default: false,
    },
    allowedDates: {
      default: null,
    },
    prefix: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    value: {},
  },
  setup() {
    return {
      icons: {
        mdiCalendar,
        mdiClockTimeFourOutline,
        mdiPaperclip,
      },
    }
  },
  data() {
    return {
      menu: false,
      checkboxInput: [],
      rules: {
        textRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        numberRules: [
          ...this.customChecking,
          v => {
            return this.validateNumber
              ? this.allowEmpty
                ? this.$validate.DataValid(v)
                  ? this.$validate.isNumber(v)
                  : true
                : this.$validate.isNumber(v)
              : true
          },
          v => {
            return this.validateFloating
              ? this.allowEmpty
                ? this.$validate.DataValid(v)
                  ? this.$validate.isFloating(v)
                  : true
                : this.$validate.isFloating(v)
              : true
          },
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        phoneRules: [
          ...this.customChecking,
          v => {
            return this.validatePhone
              ? this.allowEmpty
                ? this.$validate.DataValid(v)
                  ? this.$validate.mobileValid(v)
                  : true
                : this.$validate.mobileValid(v)
              : true
          },
          v => {
            return this.validateNumber
              ? this.allowEmpty
                ? this.$validate.DataValid(v)
                  ? this.$validate.isNumber(v)
                  : true
                : this.$validate.isNumber(v)
              : true
          },
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        emailRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
          v => {
            return this.$validate.emailValid(v)
          },
        ],
        password: [
          v => (this.required ? !!v || '此欄不能留空' : true),
          v => /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/.test(v) || '無效的密碼格式',
        ],
        dateRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        dateRangeRules: [
          ...this.customChecking,

          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        timeRules: [v => (this.required ? !!v || '此欄不能留空' : true)],
        uploadRules: [v => (this.required ? (!!v && v.size < 2000000) || '文件大小必須小於2MB' : true)],
      },
      selectDate: '',
      saveSelectDate: '',
      selectDateRange: [],
      saveSelectDateRange: [],
      inputTime: '',
      checkboxVal: false,
    }
  },
  computed: {
    ...mapState({
      state: state => state,
      isFetching: state => state.request.isFetching,
    }),
    dateRangeValue() {
      return Array.isArray(this.value) && this.value.length > 0 && this.value[0] ? this.value.join(' 至 ') : ''
    },
    minDate() {
      return moment().format('YYYY-MM-DD')
    },
  },
  watch: {
    value: {
      handler(val) {
        if (this.inputType === 'datePicker') {
          if (this.$validate.DataValid(val)) {
            this.selectDate = val
            this.saveSelectDate = val
          } else {
            this.selectDate = ''
            this.saveSelectDate = ''
          }
        } else if (this.inputType === 'dateRangePicker') {
          if (this.$validate.DataValid(val)) {
            if (Array.isArray(val)) {
              this.selectDateRange = val
              this.saveSelectDateRange = val
            } else {
              this.selectDateRange = [val]
              this.saveSelectDateRange = [val]
            }
          } else {
            this.selectDateRange = []
            this.saveSelectDateRange = []
          }
        } else if (this.inputType === 'checkbox') {
          if (this.options.length > 0) {
            if (Array.isArray(val)) {
              this.checkboxInput = val
            }
          } else {
            if (typeof val === 'boolean') {
              this.checkboxVal = val
            }
          }
        } else if (this.inputType === 'digitalTimePicker') {
          this.inputTime = val
        }
      },
      immediate: true,
    },
    inputTime: {
      handler(val) {
        this.$emit('input', val)
      },
      immediate: true,
    },
  },
  methods: {
    updateCheckbox(event) {
      this.$emit('input', event)
    },
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.$Formatter.displayWeekday(date[1])}) ~ ${
            date[0]
          } (${this.$Formatter.displayWeekday(date[0])})`
        } else {
          return `${date[0]} (${this.$Formatter.displayWeekday(date[0])}) ~ ${
            date[1]
          } (${this.$Formatter.displayWeekday(date[1])})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.$Formatter.displayWeekday(date[0])})`
      }

      return ''
    },
    formatInput(event) {
      if (this.inputNumberOnly) {
        return this.$format.number(event)
      } else if (this.inputFloatingOnly) {
        return this.$format.floating(event)
      }
    },
    onFilePicked(file) {
      if (file != null) {
        const imageName = file.name
        if (imageName.lastIndexOf('.') <= 0) {
          return
        }
        this.$emit('input', file)
      } else {
        this.$emit('input', null)
      }
    },
  },
}
</script>