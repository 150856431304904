<template>
  <v-menu
    content-class="timepicker-menu"
    offset-y
    :close-on-content-click="false"
    :max-width="$vuetify.breakpoint.smAndUp ? '250px' : '70%'"
    v-model="showMenu"
    :disabled="disableMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :readonly="true"
        type="text"
        outlined
        hide-details="auto"
        v-model="timeStr"
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="[
          ...customChecking,
          v => {
            return required ? $validate.required(v) || '此位置不能留空' : true
          },
        ]"
        :error="showError"
      >
      </v-text-field>
    </template>

    <div class="d-flex align-center justify-space-between" style="background-color: white">
      <v-select
        class="timepicker-select"
        :items="hourList"
        solo
        flat
        hide-details="auto"
        append-icon=""
        :value="hour"
        @change="updatePicker($event, 'hour')"
      >
      </v-select>
      <span>:</span>
      <v-select
        class="timepicker-select"
        :items="minList"
        solo
        flat
        hide-details="auto"
        append-icon=""
        :value="min"
        @change="updatePicker($event, 'min')"
      >
      </v-select>
      <template v-if="showSecondField">
        <span>:</span>
        <v-select
          class="timepicker-select"
          :items="secList"
          solo
          flat
          hide-details="auto"
          append-icon=""
          :value="sec"
          @change="updatePicker($event, 'sec')"
        >
        </v-select>
      </template>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'CustomDigitalTimepicker',
  props: {
    time: {
      type: String,
      default: '',
      require: true,
    },
    label: {
      type: String,
      default: '',
      require: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
    hourList: {
      type: Array,
      default: () => [],
      required: false,
    },
    minList: {
      type: Array,
      default: () => [],
      required: false,
    },
    secList: {
      type: Array,
      default: () => [],
      required: false,
    },
    customChecking: {
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSecondField: {
      type: Boolean,
      default: false,
      required: false,
    },
    showError: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    showMenu: false,
    timeStr: '',
    hour: '',
    min: '',
    sec: '',
  }),
  methods: {
    updatePicker(event, type) {
      if (type === 'hour') {
        this.hour = event

        if (!this.$validate.DataValid(this.min)) {
          this.min = '00'
        }

        if (this.showSecondField) {
          if (!this.$validate.DataValid(this.sec)) {
            this.sec = '00'
          }
        }
      } else if (type === 'min') {
        this.min = event

        if (!this.$validate.DataValid(this.hour)) {
          this.hour = '00'
        }

        if (this.showSecondField) {
          if (!this.$validate.DataValid(this.sec)) {
            this.sec = '00'
          }
        }
      } else if (type === 'sec') {
        this.sec = event

        if (!this.$validate.DataValid(this.hour)) {
          this.hour = '00'
        }

        if (!this.$validate.DataValid(this.min)) {
          this.min = '00'
        }
      }

      if (this.showSecondField) {
        this.$emit('update:time', `${this.hour}:${this.min}:${this.sec}`)

        if (
          type === 'sec' &&
          this.$validate.DataValid(this.hour) &&
          this.$validate.DataValid(this.min) &&
          this.$validate.DataValid(this.sec)
        ) {
          this.showMenu = false
        }
      } else {
        this.$emit('update:time', `${this.hour}:${this.min}`)

        if (type === 'min' && this.$validate.DataValid(this.hour) && this.$validate.DataValid(this.min)) {
          this.showMenu = false
        }
      }
    },
  },
  watch: {
    time: {
      handler(val) {
        this.timeStr = val

        if (this.$validate.DataValid(val)) {
          const timeArr = val.split(':')
          if (timeArr.length > 0) {
            this.hour = timeArr[0]
            this.min = timeArr[1]
          }

          if (this.showSecondField) {
            if (timeArr.length >= 2) {
              this.sec = timeArr[2]
            } else if (timeArr.length > 0) {
              this.sec = '00'
            }
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (
      !this.$validate.DataValid(this.hourList) ||
      (this.$validate.DataValid(this.hourList) && this.hourList.length <= 0)
    ) {
      // 00 - 23
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          this.hourList.push(`0${i}`)
        } else {
          this.hourList.push(`${i}`)
        }
      }
    }

    if (
      !this.$validate.DataValid(this.minList) ||
      (this.$validate.DataValid(this.minList) && this.minList.length <= 0)
    ) {
      // 00 - 59
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          this.minList.push(`0${i}`)
        } else {
          this.minList.push(`${i}`)
        }
      }
    }

    if (this.showSecondField) {
      if (
        !this.$validate.DataValid(this.secList) ||
        (this.$validate.DataValid(this.secList) && this.secList.length <= 0)
      ) {
        // 00 - 59
        for (let i = 0; i < 60; i++) {
          if (i < 10) {
            this.secList.push(`0${i}`)
          } else {
            this.secList.push(`${i}`)
          }
        }
      }
    }
  },
}
</script>
